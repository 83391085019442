import styled from '@emotion/styled'
import { spaceX, spaceY } from 'driverama-core/styles/spacing'
import { media } from 'driverama-core/styles/media'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { color, time, zIndex } from 'driverama-core/styles/variables'
import { css } from '@emotion/react'

export const SContainer = styled.div<{ reverse?: boolean }>`
  ${spaceY(4)}
  z-index: ${zIndex('order-1')};

  ${({ reverse }) =>
    !reverse
      ? css`
          grid-column-start: col-1;
          grid-column-end: col-3;
        `
      : css`
          grid-column-start: col-end 8;
          grid-column-end: col-end 11;
        `}

  @media ${media.lte('mobile')} {
    grid-column-start: col-1;
    grid-column-end: col-4;
  }
`

export const SProgressContainer = styled.div`
  display: flex;
  align-items: center;

  ${spaceX(6)};
`

export const SProgressBarContainer = styled.div`
  width: 100%;
  height: 2px;

  overflow: hidden;

  background: ${color('night-l-650')};
  border-radius: 2px;
`

export const SProgressBar = styled.div<{ progress: number }>`
  width: 100%;
  height: 2px;

  transform: scaleX(${({ progress }) => progress});
  transform-origin: left;

  background: ${color('night-l-100')};
  transition: transform ${time('control')};
`

export const SArrowsContainer = styled.div`
  display: flex;
  align-items: center;

  ${spaceX(12)}

  @media ${media.lte('mobile')} {
    ${spaceX(6)}
  }
`

export const SArrowButton = styled(ButtonTransparent)`
  color: ${color('night-l-100')};

  &[disabled] {
    color: ${color('night-l-650')};
  }
`
