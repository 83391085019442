import { css, Global } from '@emotion/react'
import { UserReviewFragment } from 'api/contentful/generated/contentful'
import { HotDeals } from 'components/algolia/widgets/HotDeals'
import { NewArrivals } from 'components/algolia/widgets/NewArrivals'
import { Layout } from 'components/layout/Layout'
import { UserReviews } from 'components/userReview/UserReviews'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import IconPromise1 from 'images/icons/purchase/IconPromise1.svg'
import IconPromise4 from 'images/icons/purchase/IconPromise4.svg'
import IconPromise5 from 'images/icons/purchase/IconPromise5.svg'

import IconPromiseFinancing from 'images/icons/purchase/IconPromiseFinancing.svg'
import IconPromiseTooltip from 'images/icons/purchase/IconPromiseTooltip.svg'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Footer } from 'sections/footer/Footer'
import { Header } from 'sections/header/Header'
import { HeaderMenu } from 'sections/header/menu/HeaderMenu'
import { PurchaseHomepageBottomTrackBackground } from 'sections/homepage/purchase/bottomTrackBackground/PurchaseHomepageBottomTrackBackground'
import { PurchaseHomepageFairPriceEstimate } from 'sections/homepage/purchase/fairPriceEstimate/PurchaseHomepageFairPriceEstimate'
import { PurchaseHomepageFinancingOffer } from 'sections/homepage/purchase/financingOffer/PurchaseHomepageFinancingOffer'
import { PurchaseHomepageFooter } from 'sections/homepage/purchase/footer/PurchaseHomepageFooter'
import { PurchaseHomepageHero } from 'sections/homepage/purchase/hero/PurchaseHomepageHero'
import { SeoHeader } from 'sections/seo/SeoHeader'
import {
  initAlgoliaAnalytics,
  useAAUserToken
} from 'sections/serp/SerpAnalytics'
import { useGtmPageView } from 'utils/gtm/useGtmPageView'
import { HomepageMap } from '../map/HomepageMap'
import { PurchaseHomepageMapHeader } from './mapHeader/PurchaseHomepageMapHeader'
import { PurchaseHomepagePromiseList } from './promises/PurchaseHomepagePromiseList'
import { SNewArrivalsContainer } from './PurchaseHomepage.styled'
import { PurchaseHomepageTradeIn } from './tradeIn/PurchaseHomepageTradeIn'

interface Props {
  reviews: UserReviewFragment[]
}

initAlgoliaAnalytics()

export function PurchaseHomepage(props: Props) {
  const { t } = useTranslation(['core', 'purchaseHomepage'])

  useAAUserToken()

  const { logPageView } = useGtmPageView()

  useEffect(() => {
    logPageView({ parameters: { page_data: { locale: 'buying' } } })
  }, [logPageView])

  const promises = [
    {
      icon: <IconPromise4 />,
      text: t('purchaseHomepage:promise_moneyback_label'),
      content: t('purchaseHomepage:promise_moneyback_tooltip')
    },
    {
      icon: <IconPromise5 />,
      text: t('purchaseHomepage:promise_extended_warranty_label'),
      content: t('purchaseHomepage:promise_extended_warranty_tooltip')
    },
    {
      icon: <IconPromise1 />,
      text: t('purchaseHomepage:promise_inspection_label'),
      content: t('purchaseHomepage:promise_inspection_tooltip')
    },
    {
      icon: <IconPromiseFinancing />,
      text: t('purchaseHomepage:promise_financing_label'),
      content: t('purchaseHomepage:promise_financing_tooltip')
    },
    {
      icon: <IconPromiseTooltip />,
      text: t('purchaseHomepage:promise_testdrive_label'),
      content: t('purchaseHomepage:promise_testdrive_tooltip')
    }
  ]

  return (
    <>
      <SeoHeader
        title={t('purchaseHomepage:meta_title')}
        description={t('purchaseHomepage:meta_description')}
      />

      <Global
        styles={css`
          #__next {
            overflow-x: visible;
            overflow-x: clip;
          }
        `}
      />

      <Layout
        header={
          <Header
            variant="transparent"
            isHomepage
            menu={<HeaderMenu variant="light" withContactPhone />}
            logoColor="sun-glow"
          />
        }
        footer={<Footer isHomepage />}
      >
        <PurchaseHomepageHero />

        {!driverama.flags.IS_SELLING_SHUT_DOWN && (
          <SNewArrivalsContainer>
            <NewArrivals page="sell_landing" multiIndex headless />
          </SNewArrivalsContainer>
        )}

        <Spacer
          axis="vertical"
          size={[20, 20, driverama.flags.IS_SELLING_SHUT_DOWN ? 20 : 0]}
        />

        <HomepageMap header={<PurchaseHomepageMapHeader />} />

        {!driverama.flags.IS_SELLING_SHUT_DOWN && (
          <>
            <Spacer axis="vertical" size={[20, 20, 0]} />
            <HotDeals page="sell_landing" multiIndex />
          </>
        )}

        <Spacer axis="vertical" size={[12, 32]} />

        <PurchaseHomepagePromiseList
          title={t('purchaseHomepage:promise_title')}
          promises={promises}
        />

        <Spacer axis="vertical" size={[20, 20, 45]} />

        <PurchaseHomepageFairPriceEstimate />

        <Spacer axis="vertical" size={[20, 20, 25]} />

        <PurchaseHomepageBottomTrackBackground>
          <Spacer axis="vertical" size={[0, 0, 45]} />

          <PurchaseHomepageFinancingOffer />

          <Spacer axis="vertical" size={[15, 15, 50]} />

          <PurchaseHomepageTradeIn />

          <Spacer axis="vertical" size={[20, 20, 50]} />

          <UserReviews
            reviews={props.reviews}
            title={t('purchaseHomepage:reviews_title')}
            subtitle={t('purchaseHomepage:reviews_subtitle')}
            description={t('purchaseHomepage:reviews_description')}
            reverse
          />

          <Spacer axis="vertical" size={[20, 20, 50]} />
        </PurchaseHomepageBottomTrackBackground>

        <PurchaseHomepageFooter />

        <Spacer axis="vertical" size={[20, 20, 40]} />
      </Layout>
    </>
  )
}
