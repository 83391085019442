import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { media } from 'driverama-core/styles/media'
import { motion } from 'framer-motion'
import { color, radius, zIndex } from 'driverama-core/styles/variables'

export const SWrapper = styled(motion.div)`
  display: none;

  position: absolute;
  bottom: ${size(25)};
  right: 0;
  z-index: ${zIndex('order-1')};

  grid-column-end: col-end 12;

  width: ${size(40)};
  height: ${size(28)};

  padding: ${size(4)};

  //backdrop-filter: blur(25px);
  background-color: ${color('black', 0.6)};
  border-radius: ${radius('corner-small')};
  overflow: hidden;

  @media ${media.gt('tablet')} {
    display: block;
  }
`
