import styled from '@emotion/styled'
import { layoutGrid } from 'driverama-core/styles/common'
import { media } from 'driverama-core/styles/media'

export const SContainer = styled.section`
  ${layoutGrid};
`

export const SContent = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;
  grid-row: 1;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 8;
    grid-column-end: col-end 12;
    grid-row: 1;
  }
`

export const SForm = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;
  grid-row: 2;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 1;
    grid-column-end: col-start 7;
    grid-row: 1;
  }

  @media ${media.gt('XL')} {
    grid-column-end: col-start 6;
  }
`

export const SHeaderWrap = styled.div`
  max-width: 100%;
`
