import { color } from 'driverama-core/styles/variables'
import { TextBody } from 'driverama-core/components/text/Text'
import IconArrowLeft from 'driverama-core/images/icons/IconArrowLeft.svg'
import IconArrowRight from 'driverama-core/images/icons/IconArrowRight.svg'
import {
  SArrowButton,
  SArrowsContainer,
  SContainer,
  SProgressBar,
  SProgressBarContainer,
  SProgressContainer
} from './CarouselControls.styled'

interface Props<T> {
  items: T[]
  pageState: Record<string, boolean>
  handleArrowClick: (diff: number, currPage: number) => void
  reverse?: boolean
}

export function CarouselControls<T>({
  items,
  pageState,
  handleArrowClick,
  reverse
}: Props<T>) {
  const rightPage = items.length.toString()
  const leftPage =
    Object.entries(pageState)
      .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
      .find(([_, v]) => v)?.[0] ?? rightPage

  const progress = Number.parseInt(leftPage) / Number.parseInt(rightPage)

  return (
    <SContainer reverse={reverse}>
      <SProgressContainer>
        <TextBody
          variant="setup"
          size="large"
          css={{ color: color('night-l-100') }}
        >
          {leftPage.padStart(2, '0')}
        </TextBody>

        <SProgressBarContainer>
          <SProgressBar progress={progress} />
        </SProgressBarContainer>

        <TextBody
          variant="setup"
          size="large"
          css={{ color: color('night-l-650') }}
        >
          {items.length?.toString().padStart(2, '0')}
        </TextBody>
      </SProgressContainer>

      <SArrowsContainer>
        <SArrowButton
          onClick={() =>
            handleArrowClick(reverse ? 1 : -1, Number.parseInt(leftPage))
          }
          disabled={Number.parseInt(leftPage) === 1}
        >
          <IconArrowLeft />
        </SArrowButton>

        <SArrowButton
          onClick={() =>
            handleArrowClick(reverse ? -1 : 1, Number.parseInt(leftPage))
          }
          disabled={leftPage === rightPage}
        >
          <IconArrowRight />
        </SArrowButton>
      </SArrowsContainer>
    </SContainer>
  )
}
