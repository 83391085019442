import styled from '@emotion/styled'
import { TextHeader } from 'driverama-core/components/text/Text'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SHeader = styled.div`
  grid-column-start: col-left;
  grid-column-end: col-right;
  padding: 0 ${size(4)};
`
export const STextHeader = styled(TextHeader)`
  text-align: center;
  margin: 0 auto;
  max-width: ${size(132)};

  @media ${media.lte('mobile')} {
    max-width: 300px;
    text-align: left;
    margin: unset;
  }
`

export const SSubtitle = styled.div`
  max-width: ${size(138)};
  text-align: center;
  margin: ${size(4)} auto 0;

  @media ${media.lte('mobile')} {
    text-align: left;
    max-width: ${size(82)};
    margin: ${size(4)} 0 0;
  }
`
