import styled from '@emotion/styled'
import { layoutGrid } from 'driverama-core/styles/common'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SContainer = styled.section`
  ${layoutGrid};
  max-width: 100vw;
  overflow: hidden;
  align-items: center;
`

export const SImageContainer = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;
  grid-row: 1;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 5;
    grid-column-end: col-end 12;
    grid-row: 1;
  }
`

export const SContent = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;
  grid-row: 2;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 1;
    grid-column-end: col-start 6;
    grid-row: 1;
  }
`

export const SBottom = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;
  grid-row: 3;

  @media ${media.gt('MD')} {
    grid-column-start: col-start 1;
    grid-column-end: col-end 2;
  }

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 6;
    grid-column-end: col-end 10;
    grid-row: 2;
  }
`

export const SImageWrap = styled.div`
  pointer-events: none;
  text-align: center;

  width: calc(100% + ${size(20)});
  transform: translateX(-${size(10)});

  @media ${media.gt('tablet')} {
    transform: translateX(${size(20)});
  }
`
