import { getFaqQuestions } from 'api/contentful/faq'
import {
  FaqQuestionFragment,
  UserReviewFragment
} from 'api/contentful/generated/contentful'
import { getUserReviews } from 'api/contentful/reviews'
import { HydrateProps } from 'components/algolia/AlgoliaHydrate'
import { STATIC_DATA_REVALIDATION_TIME } from 'constants/api'
import { GetStaticProps } from 'next'
import { InstantSearch } from 'react-instantsearch-dom'
import { resolveContentfulLocale } from 'sections/blog/articles/BlogArticles.utils'
import { PurchaseHomepage } from 'sections/homepage/purchase/PurchaseHomepage'
import { SellHomepage } from 'sections/homepage/sell/SellHomepage'
import { dehydrateAlgoliaState } from 'sections/serp/hydrate/SerpHydrate'
import { searchClient } from 'sections/serp/Serp.utils'
import { getIntlProps } from 'utils/intl'

type Props = {
  reviews: UserReviewFragment[]
  questions: FaqQuestionFragment[]
  searchResultsState?: HydrateProps
}

function PageWrapper({
  resultsState,
  widgetsCollector,
  reviews
}: HydrateProps & { reviews: UserReviewFragment[] }) {
  return (
    <InstantSearch
      indexName={process.env.NEXT_PUBLIC_ALGOLIA_STOCK_CARS_INDEX as string}
      searchClient={searchClient}
      resultsState={resultsState}
      widgetsCollector={widgetsCollector}
    >
      <PurchaseHomepage reviews={reviews} />
    </InstantSearch>
  )
}

function Page(props: Props) {
  if (
    !driverama.flags.IS_SELLING_ENABLED ||
    driverama.flags.IS_SELLING_SHUT_DOWN
  ) {
    return <SellHomepage reviews={props.reviews} questions={props.questions} />
  }

  return (
    <PageWrapper
      resultsState={props.searchResultsState}
      reviews={props.reviews}
    />
  )
}

export const getStaticProps: GetStaticProps<Props> = async ctx => {
  const intlProps = await getIntlProps(
    ctx,
    driverama.flags.IS_SELLING_SHUT_DOWN
      ? ['sell', 'presskit']
      : ['purchaseHomepage', 'sell', 'presskit']
  )

  const locale = resolveContentfulLocale(ctx.locale)

  if (driverama.flags.IS_SELLING_SHUT_DOWN) {
    const [reviews, questions] = await Promise.all([
      getUserReviews(locale),
      getFaqQuestions('homepage', locale)
    ])

    return {
      props: {
        ...intlProps,
        reviews,
        questions
      },
      revalidate: STATIC_DATA_REVALIDATION_TIME
    }
  }

  const [reviews, questions, search] = await Promise.all([
    getUserReviews(locale),
    getFaqQuestions('homepage', locale),
    dehydrateAlgoliaState(PageWrapper, {
      searchClient,
      indexName: process.env.NEXT_PUBLIC_ALGOLIA_STOCK_CARS_INDEX as string
    })
  ])

  return {
    props: {
      ...intlProps,
      questions,
      reviews,
      searchResultsState: JSON.parse(JSON.stringify(search.resultsState))
    },
    revalidate: STATIC_DATA_REVALIDATION_TIME
  }
}

export default Page
