import { useCustomerSelfQuery } from 'driverama-core/api/driverama/customers/customerSelf'
import { useSession } from 'driverama-core/utils/auth'
import { useEffect, useState } from 'react'
import algoliaAnalytics from 'search-insights'

export const initAlgoliaAnalytics = () =>
  algoliaAnalytics('init', {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY as string,
    useCookie: true
  })

// TODO: migrate this logic to GTM instead
/** @deprecated */
export const useAAUserToken = () => {
  const { loading: isAuthSessionLoading, session: authSession } = useSession()
  const { data: customerData } = useCustomerSelfQuery({
    enabled: !isAuthSessionLoading && !!authSession?.access_token
  })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (customerData) {
      // TODO: use token for recommended products when available
      algoliaAnalytics('setUserToken', customerData.id)
    }
    setIsLoading(false)
  }, [customerData])

  return {
    isLoading
  }
}
