import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'

export const STrackWrap = styled.div`
  width: 100%;

  @media ${media.gt('tablet')} {
    background: url('images/purchaseWizard/PurchaseTrack.svg') no-repeat center
      top;
  }
`
