import styled from '@emotion/styled'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { TextBody } from 'driverama-core/components/text/Text'
import { color, time } from 'driverama-core/styles/variables'

export const SText = styled(TextBody)`
  color: inherit;
`

export const SContainer = styled(ButtonTransparent)`
  color: ${color('night-l-100')};

  ${SText}, svg {
    transition: all ${time('control')} ease-in-out;
  }

  @media (hover: hover) {
    &:hover {
      color: ${color('night-d-100')};
    }
  }
`

export const SLink = styled.a`
  display: flex;
  justify-content: flex-start;

  color: ${color('night-l-100')};

  ${SText}, svg {
    color: ${color('night-l-100')};
    transition: all ${time('control')} ease-in-out;
  }

  @media (hover: hover) {
    &:hover {
      color: ${color('night-d-100')};

      ${SText}, svg {
        color: ${color('night-d-100')};
      }
    }
  }
`
