import styled from '@emotion/styled'
import {
  getResponsiveStyles,
  ResponsiveValue
} from 'driverama-core/styles/responsivity'

export const AspectRatio = styled.div<{
  ratio: ResponsiveValue<number>
}>`
  position: relative;

  &:before {
    content: '';
    height: 0;
    width: 0;
    display: block;

    ${({ ratio }) =>
      getResponsiveStyles(ratio, value => ({
        paddingTop: `calc(${1 / value} * 100%)`
      }))}
  }
`
