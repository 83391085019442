import { useTranslation } from 'react-i18next'
import { connectStateResults } from 'react-instantsearch-dom'
import { PropsWithChildren } from 'react'
import { Index, StateResultsProvided } from 'react-instantsearch-core'
import { LINKS } from 'constants/links'
import { SButtonSearch } from './PurchaseHomepageHeroShowCarsButton.styled'
import Link from 'next/link'
import { AlgoliaStockCarResponse } from 'sections/serp/Serp.types'
import { HydrateProps } from 'components/algolia/AlgoliaHydrate'
import { logGtmCtaClick } from 'utils/gtm/gtmEvents'

function PurchaseHomepageHeroShowCarsButtonConnected({
  searchResults
}: PropsWithChildren<Partial<StateResultsProvided<AlgoliaStockCarResponse>>>) {
  const { t } = useTranslation(['purchaseHomepage'])
  return (
    <Link href={LINKS.carsSearch} passHref>
      <SButtonSearch
        variant="secondary"
        cornerRadius="small"
        as="a"
        onClick={() => logGtmCtaClick('search_cars')}
      >
        {t('purchaseHomepage:hero_button_search', {
          n: searchResults?.nbHits
        })}
      </SButtonSearch>
    </Link>
  )
}

const PurchaseHomepageHeroShowCarsButtonConnector = connectStateResults(
  PurchaseHomepageHeroShowCarsButtonConnected
)

export function PurchaseHomepageHeroShowCarsButton(props: HydrateProps) {
  return (
    <Index
      indexName={process.env.NEXT_PUBLIC_ALGOLIA_STOCK_CARS_INDEX as string}
    >
      <PurchaseHomepageHeroShowCarsButtonConnector />
    </Index>
  )
}
