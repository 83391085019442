import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TextHeader } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, shadow } from 'driverama-core/styles/variables'
import { motion } from 'framer-motion'

export const SWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;

  color: ${color('night-l-100')};
`

export const SLabel = styled(TextHeader)`
  color: inherit;
  max-width: ${size(50)};
`

export const SIcon = styled.div<{ variant?: 'sell' | 'purchase' }>`
  border-radius: ${radius('corner')};
  display: grid;
  height: ${size(14)};
  margin-right: ${size(4)};
  place-items: center;
  flex-shrink: 0;
  width: ${size(14)};

  ${({ variant }) =>
    variant === 'purchase'
      ? css`
          color: ${color('white')};
          background-color: ${color('night-l-200')};
          box-shadow: ${shadow(3)};
        `
      : css`
          color: ${color('sun-d-200')};
          background-color: ${color('sun-l-500')};
        `}
`
