import { css } from '@emotion/react'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import IconArrowRight from 'driverama-core/images/icons/IconArrowRight.svg'
import { getResponsiveStyles } from 'driverama-core/styles/responsivity'
import { SContainer, SLink, SText } from './ButtonArrow.styled'
import { forwardRef, ElementType } from 'react'

type Props = {
  text: string
  onClick?: () => void
  arrowPosition?: 'left' | 'right'
  arrowDirection?: 'left' | 'right' | 'top' | 'bottom'
  className?: string
  href?: string
  target?: '_blank'
  as?: ElementType
}

export const ButtonArrow = forwardRef<HTMLButtonElement, Props>(
  (props: Props, ref) => {
    const {
      text,
      onClick,
      arrowDirection,
      arrowPosition,
      className,
      href,
      target,
      as
    } = props

    function renderContent() {
      return (
        <>
          <SText variant="setup">{text}</SText>

          <Spacer size={4} axis="horizontal" />

          <IconArrowRight
            css={css`
              display: block;
              ${getResponsiveStyles(arrowDirection ?? 'right', {
                top: css`
                  transform: rotate(270deg);
                `,
                left: css`
                  transform: rotate(180deg);
                `,
                bottom: css`
                  transform: rotate(90deg);
                `,
                right: css`
                  transform: rotate(0deg);
                `
              })}
            `}
          />
        </>
      )
    }

    if (href) {
      return (
        <SLink
          as={as}
          className={className}
          href={href}
          target={target}
          rel={target === '_blank' ? 'noopener noreferrer' : undefined}
          onClick={onClick}
          css={getResponsiveStyles(arrowPosition ?? 'right', {
            left: {
              flexDirection: 'row-reverse'
            },
            right: {
              flexDirection: 'row'
            }
          })}
        >
          {renderContent()}
        </SLink>
      )
    }

    return (
      <SContainer
        as={as}
        ref={ref}
        className={className}
        onClick={onClick}
        css={getResponsiveStyles(arrowPosition ?? 'right', {
          left: {
            flexDirection: 'row-reverse'
          },
          right: {
            flexDirection: 'row'
          }
        })}
      >
        {renderContent()}
      </SContainer>
    )
  }
)
