import styled from '@emotion/styled'
import { color, radius } from 'driverama-core/styles/variables'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { Button } from 'driverama-core/components/button/Button'
import { AspectRatio } from 'driverama-core/components/aspectRatio/AspectRatio'
import { inset } from 'driverama-core/styles/inset'

export const SContainer = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  display: grid;
  padding: ${size(6)};

  background-color: ${color('night-d-100')};
  border-radius: ${radius('corner-large')};

  ${TextHeader} {
    color: ${color('night-l-800')};
  }

  ${TextBody} {
    color: ${color('night-text-light')};
  }

  @media ${media.gt('MD')} {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'content image';

    grid-column-gap: ${size(4)};
    align-items: center;
    grid-gap: ${size(13)};
  }

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 2;
    grid-column-end: col-end 11;

    padding: ${size(10)};
    grid-gap: ${size(16)};
  }
`

export const SContent = styled.div`
  @media ${media.gt('MD')} {
    grid-area: content;
    padding: ${size(6)};
  }
`

export const SImage = styled(AspectRatio)`
  position: relative;
  width: 100%;
  max-width: 392px;

  margin: 0 auto;

  @media ${media.gt('MD')} {
    grid-area: image;
    width: 392px;
  }
`

export const SImageWrapper = styled.div`
  --ratio: (649 / 392);

  position: absolute;
  ${inset(0)}

  width: calc(var(--ratio) * 100%);
  transform: translateX(calc((1 - 1 / var(--ratio)) * 100% / -2));
`

export const SButton = styled(Button)`
  width: 100%;
  padding: ${size(4)} ${size(3)};
`

export const SSecondaryButton = styled(SButton)`
  &,
  &:hover,
  &:active {
    color: ${color('white')};
  }
  @media ${media.lte('MD')} {
    margin-bottom: ${size(4)};
  }
  @media ${media.gt('MD')} {
    margin-left: ${size(6)};
  }
`

export const SButtonList = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${media.gt('MD')} {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;

    max-width: ${size(115)};
  }

  button {
    white-space: nowrap;
  }
`
