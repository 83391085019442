import styled from '@emotion/styled'
import { layoutGrid } from 'driverama-core/styles/common'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { Button } from 'driverama-core/components/button/Button'
import { media } from 'driverama-core/styles/media'
import { mobileMenuHeight } from 'sections/footer/MobileMenu'
import { inset } from 'driverama-core/styles/inset'

export const SContainer = styled.section`
  --card-radius: ${size(6)};

  position: relative;

  display: grid;
  grid-template-rows: 1fr auto;

  @media ${media.lte('tablet')} {
    min-height: 80vh;

    @supports (position: sticky) {
      min-height: calc(100vh - ${mobileMenuHeight}px);
    }

    @supports (min-height: 100svh) {
      min-height: calc(100svh + var(--card-radius) - ${mobileMenuHeight}px);
    }
  }

  @media ${media.gt('tablet')} {
    height: ${size(200)};
  }

  @media ${media.lte('tablet')} and (orientation: landscape) {
    min-height: max(100vh, ${size(100)});
  }
`

export const SImage = styled.div`
  position: relative;
  overflow: hidden;

  background-image: linear-gradient(
    180deg,
    #305b98 0%,
    #458dc2 50%,
    #468dc3 60%
  );

  z-index: -2;

  @media ${media.lte('tablet')} {
    margin-bottom: calc(var(--card-radius) * -1);
  }

  @media ${media.gt('tablet')},
    ${media.lte('tablet')} and (orientation: landscape) {
    margin: 0;
  }
`

export const SContent = styled.div`
  @media ${media.lte('tablet')} {
    position: sticky;
    align-self: start;

    bottom: ${mobileMenuHeight}px;
    bottom: calc(${mobileMenuHeight}px + env(safe-area-inset-bottom));

    grid-row: 2;
    grid-column: 1;
  }

  @media ${media.gt('tablet')},
    ${media.lte('tablet')} and (orientation: landscape) {
    position: absolute;

    left: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    grid-row: unset;
    grid-column: unset;

    ${layoutGrid};
  }
`

export const SCard = styled.div`
  border-top-left-radius: var(--card-radius);
  border-top-right-radius: var(--card-radius);

  padding: ${size(4)};
  padding-bottom: var(--card-radius);

  position: relative;
  align-self: center;

  ${TextHeader} {
    color: ${color('white')};
  }

  ${TextBody} {
    color: ${color('white', 0.7)};
  }

  @media ${media.gt('tablet')},
    ${media.lte('tablet')} and (orientation: landscape) {
    background: ${color('black', 0.7)};
    border-radius: ${radius('corner-mid')};
  }

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 1;
    grid-column-end: col-end 5;

    padding: ${size(14)};
  }

  @media ${media.lte('tablet')} and (orientation: landscape) {
    grid-column-start: col-start 1;
    grid-column-end: col-start 3;

    padding: ${size(4)};

    h1 {
      font-size: ${size(6)};
      line-height: ${size(8)};
    }
  }
`

export const SCardBackground = styled.div`
  position: absolute;
  z-index: -1;

  pointer-events: none;
  background: #363636;

  border-top-left-radius: var(--card-radius);
  border-top-right-radius: var(--card-radius);

  overflow: hidden;

  ${inset(0)};

  @media ${media.gt('tablet')},
    ${media.lte('tablet')} and (orientation: landscape) {
    display: none;
  }
`

export const STextWhite = styled(TextBody)`
  display: inline;
  color: ${color('white')};
`

export const SButtonSell = styled(Button)`
  width: 100%;

  padding-left: 0;
  padding-right: 0;

  && {
    color: ${color('white')};
    border-color: ${color('white', 0.4)};

    @media (hover: hover) {
      &:hover {
        border-color: ${color('white')};
      }
    }
  }
`

export const STrustpilotContainer = styled.div`
  ${layoutGrid};
  margin-top: ${size(10)};

  @media ${media.gt('tablet')} {
    display: none;
  }
`

export const STrustpilot = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${size(4)};

  border: 2px solid ${color('night-l-700')};
  border-radius: ${radius('corner')};

  > div {
    width: ${size(45)};
  }
`
export const SHeroPromise = styled.div`
  display: grid;
  grid-template-columns: ${size(4)} 1fr;
  grid-column-gap: ${size(2)};

  & svg {
    min-width: ${size(3)};
    min-height: ${size(3)};
    color: ${color('white')};
  }
`

export const SBottomCornerContainer = styled.div`
  grid-row: 2;
  grid-column: 1;
  width: 100vw;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  z-index: 1;

  pointer-events: none;

  @media ${media.gt('tablet')},
    ${media.lte('tablet')} and (orientation: landscape) {
    display: none;
  }
`
