import { HydrateProps } from 'components/algolia/AlgoliaHydrate'
import { useTranslation } from 'react-i18next'
import { dehydrateAlgoliaState } from 'sections/serp/hydrate/SerpHydrate'
import { searchClient } from 'sections/serp/Serp.utils'
import { CarDetailSourcePage } from 'utils/analytics/useLogEvents'
import { CarHighlightCarousel } from '../carHighlightCarousel/CarHighlightCarousel'

interface Props {
  page: CarDetailSourcePage
  headless?: boolean
  showTrack?: boolean
  hideBrowseAll?: boolean
  multiIndex?: boolean
}

export function HotDeals({
  resultsState,
  widgetsCollector,
  page,
  headless,
  showTrack,
  hideBrowseAll,
  multiIndex
}: HydrateProps & Props) {
  const { t } = useTranslation(['core'])
  return (
    <CarHighlightCarousel
      marketingLabel="HOT_DEAL"
      source={{ page, section: 'hot_deals' }}
      subtitle={headless ? undefined : t('core:hot_deals_subtitle')}
      title={headless ? undefined : t('core:hot_deals_title')}
      emptyStateText={t('core:hot_deals_no_cars')}
      resultsState={resultsState}
      widgetsCollector={widgetsCollector}
      hideTrack={!showTrack}
      hideBrowseAll={hideBrowseAll}
      multiIndex={multiIndex}
    />
  )
}

export const fetchHotDeals = () =>
  dehydrateAlgoliaState(HotDeals, {
    searchClient,
    indexName: process.env.NEXT_PUBLIC_ALGOLIA_STOCK_CARS_INDEX as string
  })
