import styled from '@emotion/styled'
import { Button } from 'driverama-core/components/button/Button'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { radius } from 'driverama-core/styles/variables'

export const SWarrantyButton = styled(Button)`
  height: ${size(8)};
  padding: ${size(2)} ${size(8)};

  border-radius: ${radius('corner-mid')};

  display: inline-flex;
`

export const SNewArrivalsContainer = styled.div`
  @media ${media.gt('tablet')} {
    margin-top: ${size(-20)};
  }
`
