import { setUser } from '@sentry/nextjs'
import { operations } from 'driverama-core/api/driverama/generated/customers'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import {
  apiAuthFetcher,
  apiFetcher,
  getAuthorizationHeader
} from 'driverama-core/utils/fetcher'
import { useQuery, UseQueryOptions } from 'react-query'

export type CustomerSelfResponse = operations['getSelf']['responses']['200']['content']['application/com.driverama-v1+json']

type QueryOpts = UseQueryOptions<unknown, unknown, CustomerSelfResponse>

export function useCustomerSelfQuery(opts?: QueryOpts) {
  const response = useQuery(QUERY_KEYS.customerSelf, getCustomerSelf, opts)
  const { data } = response

  if (data) {
    // Do not add sensitive user data to Sentry
    // If necessary, look it up on BE based on userId
    const user = {
      userId: data.id,
      type: data.type
    }
    setUser(user)
  }

  return response
}

export async function getCustomerSelf() {
  const res = await apiAuthFetcher<CustomerSelfResponse>(URLS.customerSelf, {
    method: HTTPMethod.GET
  })

  return res.json
}

export async function prefetchCustomerSelf(accessToken?: string) {
  const res = await apiFetcher<CustomerSelfResponse>(URLS.customerSelf, {
    method: HTTPMethod.GET,
    headers: {
      ...getAuthorizationHeader(accessToken)
    }
  })

  return res.json
}

export type CustomerGender = NonNullable<CustomerSelfResponse['gender']>
export type CustomerType = NonNullable<CustomerSelfResponse['type']>
