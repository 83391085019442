import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, shadow, time } from 'driverama-core/styles/variables'
import { layoutGrid } from 'driverama-core/styles/common'
import { TextHeader } from 'driverama-core/components/text/Text'

export const SContainer = styled.div`
  position: relative;
  ${layoutGrid};
`

export const SCar = styled.div`
  pointer-events: none;

  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 6;
    grid-column-end: col-end 12;

    grid-row: 1 / 1;
  }

  & > span {
    border-radius: ${radius('corner')};
    box-shadow: ${shadow(3)};
  }
`

export const SContent = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 1;
    grid-column-end: col-end 6;

    grid-row: 1 / 1;

    z-index: 1;
  }
`

export const SHeader = styled(TextHeader)`
  max-width: ${size(125)};
  @media ${media.gt('tablet')} {
    margin-right: ${size(4)};
  }
`

export const SCards = styled.div`
  grid-column-start: col-start 1;
  grid-column-end: col-end 4;

  display: grid;
  grid-row-gap: ${size(5)};
  grid-column-gap: ${size(10)};

  @media ${media.gt('tablet')} {
    grid-column-start: col-start 1;
    grid-column-end: col-end 6;

    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const SCard = styled.div`
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  height: ${size(32)};
  padding: ${size(4)};

  background-color: ${color('white')};
  box-shadow: ${shadow(2)};
  border-radius: ${radius('corner')};

  transition: all ${time('control')} ease-in-out;

  ${TextHeader} {
    margin-top: auto;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${color('sun')};
    }
  }

  @media ${media.gt('tablet')} {
    min-height: ${size(74)};
  }

  @media ${media.lte('tablet')} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;

    ${TextHeader} {
      margin-right: auto;
    }
  }
`

export const SIcon = styled.svg`
  height: ${size(30)};
  width: auto;
  margin: auto;

  color: ${color('black')};

  @media ${media.lte('tablet')} {
    width: ${size(24)};
    height: auto;
    margin: auto 0 auto 0;
  }

  @media ${media.gt('tablet')} {
    > * {
      stroke-width: 1;
    }
  }
`
