import { useState } from 'react'
import { useTimeoutFn } from 'react-use'

export enum HeroPromiseListType {
  BUY = 'buy',
  SELL = 'sell'
}

const buy_list = [
  'hero_buy_list_1',
  'hero_buy_list_2',
  'hero_buy_list_3',
  'hero_buy_list_4'
] as const

const sell_list = ['hero_sell_list_1', 'hero_sell_list_2'] as const

export function useHeroPromiseList() {
  const [listType, setListType] = useState<HeroPromiseListType>(
    HeroPromiseListType.BUY
  )

  // switch SELL -> BUY
  useTimeoutFn(() => setListType(HeroPromiseListType.SELL), 5000)
  // switch BUY -> SELL
  useTimeoutFn(() => setListType(HeroPromiseListType.BUY), 10000)

  const items = listType === HeroPromiseListType.BUY ? buy_list : sell_list

  return { listType, setListType, items }
}
